import React from 'react'
import dynamic from 'next/dynamic'
import {TaxonomyTerm, Node, OfficeNode} from '../../types/content-types'
import {Response} from '../../types/api-types'
import {SearchResponse} from '@algolia/client-search'

const ContentPage = dynamic(() => import('./ContentPage'))
const BlogArticle = dynamic(() => import('./BlogArticle'))
const ResourceOverview = dynamic(() => import('./ResourceOverview'))
const PillarPage = dynamic(() => import('./PillarPage'))
const JobDetails = dynamic(() => import('./JobDetails'))
const Homepage = dynamic(() => import('./Homepage'))
const ApplicationForm = dynamic(() => import('./ApplicationForm'))
const OpenApplicationForm = dynamic(() => import('./OpenApplicationForm'))
const JobOverview = dynamic(() => import('./JobOverviewPage'))
const OfficePage = dynamic(() => import('./OfficePage'))
const OfficeOverview = dynamic(() => import('./OfficeOverview'))
const ExpertOverview = dynamic(() => import('./ExpertOverview'))
const CandidateDetail = dynamic(() => import('./CandidateDetail'))

type Props = {
  node: Node | null
  blockNewsResourcesAlgolia: SearchResponse | null
  resources: SearchResponse | null
  children: React.ReactNode
  country: string
  response: Response | null
  offices: OfficeNode[] | null
  officesResponse: Response | null
  countries: TaxonomyTerm[] | null
  contractTypes: TaxonomyTerm[] | null
  regions: TaxonomyTerm[] | null
  domains: TaxonomyTerm[] | null
  expertiseDomains: TaxonomyTerm[] | null
  languages: TaxonomyTerm[] | null
  functionsLevel: TaxonomyTerm[] | null
}

const contentTypes: {
  [key: string]: () => React.FC<any> | React.ComponentType<any>
} = {
  'node--content_page': () => ContentPage,
  'node--blog_article': () => BlogArticle,
  'node--resource_overview': () => ResourceOverview,
  'node--pillar_page': () => PillarPage,
  'node--vacancy': () => JobDetails,
  'node--homepage': () => Homepage,
  'node--job_overview': () => JobOverview,
  'application-form': () => ApplicationForm,
  'open-application-form': () => OpenApplicationForm,
  'node--office': () => OfficePage,
  'node--office_overview': () => OfficeOverview,
  'node--expert_overview': () => ExpertOverview,
  'node--candidate_detail_page': () => CandidateDetail,
}

const ContentTypeMapper = ({
  node,
  blockNewsResourcesAlgolia,
  resources,
  children,
  country,
  response,
  offices,
  officesResponse,
  countries,
  regions,
  domains,
  expertiseDomains,
  contractTypes,
  languages,
  functionsLevel,
}: Props) => {
  if (
    node?.type &&
    Object.prototype.hasOwnProperty.call(contentTypes, node.type)
  ) {
    const ContentTypeComponent = contentTypes[node!.type]()
    return (
      <ContentTypeComponent
        page={node}
        blockNewsResourcesAlgolia={blockNewsResourcesAlgolia}
        resources={resources}
        country={country}
        response={response}
        offices={offices}
        officesResponse={officesResponse}
        countries={countries}
        regions={regions}
        contractTypes={contractTypes}
        domains={domains}
        expertiseDomains={expertiseDomains}
        languages={languages}
        functionsLevel={functionsLevel}
      >
        {children}
      </ContentTypeComponent>
    )
  }
  return null
}

export default ContentTypeMapper
