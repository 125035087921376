import Link from 'next/link'
import GenericLink from './Link'

type Props = {
  breadCrumb: {label: string; path: string}[]
}

const Breadcrumb = ({breadCrumb}: Props) => {
  if (breadCrumb.length === 0) {
    return null
  }
  return (
    <section className="c-breadcrumb d-none d-lg-block section-box mb-0 py-3">
      <ol
        className="pl-0 d-flex"
        itemScope
        itemType="http://schema.org/BreadcrumbList"
      >
        <li
          className="d-flex align-items-center"
          itemScope
          itemType="http://schema.org/ListItem"
          itemProp="itemListElement"
        >
          <Link href="/">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="nav-link" itemProp="item">
              <meta itemProp="position" content="1" />
              <span itemProp="name">Home</span>
            </a>
          </Link>
          <span className="c-breadcrumb__delimiter"></span>
        </li>
        {breadCrumb.map((breadCrumbItem, i) => (
          <li
            className="d-flex align-items-center"
            key={breadCrumbItem.path}
            itemScope
            itemType="http://schema.org/ListItem"
            itemProp="itemListElement"
          >
            {i === breadCrumb.length - 1 ? (
              <div className="nav-link nav-link__last">
                <meta itemProp="item" content={breadCrumbItem.path} />
                <meta itemProp="position" content={String(i + 2)} />
                <span itemProp="name">{breadCrumbItem.label}</span>
              </div>
            ) : (
              <GenericLink
                extraClass="nav-link"
                link={{
                  uri: {
                    alias: breadCrumbItem.path,
                    uri: '',
                    uri_uuid: '',
                  },
                }}
              >
                <meta itemProp="item" content={breadCrumbItem.path} />
                <meta itemProp="position" content={String(i + 2)} />
                <span itemProp="name">{breadCrumbItem.label}</span>
              </GenericLink>
            )}
            {i < breadCrumb.length - 1 && (
              <span className="c-breadcrumb__delimiter"></span>
            )}
          </li>
        ))}
      </ol>
    </section>
  )
}

export default Breadcrumb
